.responseContainer {
  display: flex;
  padding: 0px;
  margin-bottom: 0px;
}

.alert {
  fill: var(--tda-warning);
}

.dateLabel {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px; /* 150% */
  letter-spacing: 0.05px;
  color: var(--tda-label);
}

.thumb {
  width: 24px;
  height: 24px;
}
.liked {
  color: var(--tda-primary-0);
}
.disliked {
  color: var(--tda-primary-0);
}

.horizontalFlip {
  transform: rotateY(180deg);
}

.agentResponse {
  width: 100%;
  display: grid;
  padding-right: 24px;
}

.agentResponse p:last-child {
  margin-bottom: 0px !important;
}

.agentLogoSize {
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 576px) {
  .agentLogoSize {
    height: 24px;
    width: 24px;
    margin-top: 4px;
  }
}
