.tda-heading-text {
  color: var(--tda-text) !important;
}

.tda-btn-text {
  color: var(--tda-text) !important;
}

.tda-btn-text:hover,
.tda-btn-text[hover] {
  background-color: var(--tda-gray-0) !important;
}

.tda-btn-text:active,
.tda-btn-text[active] {
  background-color: var(--tda-gray-1) !important;
}

.tda-btn-text[focus],
.tda-btn-text:focus {
  box-shadow: none !important;
}

.tda-btn-primary {
  background-color: var(--tda-primary) !important;
  border-color: var(--tda-primary) !important;
  color: var(--tda-primary-contrast) !important;
}

.tda-btn-primary:hover,
.tda-btn-primary[hover] {
  background-color: var(--tda-primary-hover) !important;
  border-color: var(--tda-primary-hover) !important;
}

.tda-btn-primary:active,
.tda-btn-primary[active] {
  background-color: var(--tda-primary-active) !important;
  border-color: var(--tda-primary-active) !important;
}

.tda-btn-primary[focus],
.tda-btn-primary:focus {
  box-shadow: none !important;
}

.tda-btn-tertiary {
  background-color: var(--tda-tertiary) !important;
  border-color: var(--tda-tertiary) !important;
  color: var(--tda-tertiary-contrast) !important;
}

.tda-btn-tertiary:hover,
.tda-btn-tertiary[hover] {
  background-color: var(--tda-tertiary-hover) !important;
  border-color: var(--tda-tertiary-hover) !important;
}

.tda-btn-tertiary:active,
.tda-btn-tertiary[active] {
  background-color: var(--tda-tertiary-active) !important;
  border-color: var(--tda-tertiary-active) !important;
}

.tda-btn-tertiary[focus],
.tda-btn-tertiary:focus {
  box-shadow: none !important;
}

.tda-btn-outlined {
  background-color: var(--tda-light) !important;
  border-color: var(--tda-dark) !important;
  color: var(--tda-dark) !important;
}

.tda-btn-outlined:hover,
.tda-btn-outlined[hover] {
  background-color: var(--tda-gray-0) !important;
}

.tda-btn-outlined:active,
.tda-btn-outlined[active] {
  background-color: var(--tda-gray-1) !important;
}

.tda-btn-outlined[focus],
.tda-btn-outlined:focus {
  box-shadow: none !important;
}