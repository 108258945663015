.historyItem {
  height: 32px;
  user-select: none;
  color: var(--tda-primary-contrast);
  margin-left: 1rem;
  margin-right: 0.25rem;
}

.historyItemBtn {
  cursor: pointer;
  height: 100%;
  gap: 0.5rem;
  flex: 1;
  padding-left: 1rem;
}

.historyItemText {
  width: 190px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.historyItemIconActive {
  color: var(--tda-dark);
}

.activeIndicator {
  height: 100%;
  width: 0.25rem;
}

.activeIndicatorActive {
  background-color: var(--tda-panel-active);
  color: var(--tda-dark);
  border-radius: 20px;
  font-weight: 600;
}

.historyItemTextField,
.historyItemTextField[focus],
.historyItemTextField:focus {
  background-color: var(--tda-inner-panel-background);
  color: var(--tda-panel-active);
  border-color: var(--tda-panel-border);
  border-bottom-color: var(--tda-panel-border);
  padding-left: calc(2em + 1.125rem + 2px) !important;
}

.historyItemTextField[focus],
.historyItemTextField:focus {
  border-bottom-color: var(--tda-primary-text);
}

.editIcons {
  color: var(--tda-panel-active);
}
.editIcons[hover],
.editIcons:hover {
  color: var(--tda-primary-text);
}
.editIcons[focus],
.editIcons:focus {
  color: var(--tda-primary-text);
}
