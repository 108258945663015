.sampleQueryContainer {
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
  width: 100%;
  flex: 1;
  display: grid;
  border: 2px solid var(--tda-primary);
}
.queriesContainer {
  display: flex;
}
.headerText {
  color:var(--tda-primary);
  padding-top: 24px;
  font-weight: 700;
  font-size: 32px;
}
.subheaderText {
  color:var(--tda-primary);
  padding-top: 20px;
  font-size: 20px;
}
.agentButtonContainer {
  min-width: 157px;
  font-weight: 700;
  padding: 12px 16px;
  border-radius: 9999px;
  color: var(--tda-primary-1);
  background-color: var(--tda-yellow-1);
  border: 2px solid var(--tda-yellow-2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.agentButtonContainer[hover],
.agentButtonContainer:hover {
  background-color: var(--tda-yellow-3);
}
.productButtonContainer {
  font-size: 16px;
  border-radius: 4px;
  color: var(--tda-primary-2);
  background-color: var(--tda-light);
  border: 1px solid var(--tda-primary-2);
}
.productButtonContainer[hover],
.productButtonContainer:hover {
  color: var(--tda-light);
  background-color:  var(--tda-primary-2);
}

@media screen and (max-width: 576px) {
  .sampleQueryContainer {
    flex: 0 0 100%;
  }
  .queriesContainer {
    display: contents;
  }
}
