  .headerText {
    color:var(--tda-primary-text);
    padding-top: 24px;
    font-weight: 700;
    font-size: 32px;
  }
  .subheaderText {
    color:var(--tda-primary-text);
    padding-top: 20px;
    font-size: 20px;
  }
  .headerContainer{
    padding-top: 30px;
  }
  .agentButtonContainer {
    min-width: 157px;
    font-weight: 700;
    padding: 12px 16px;
    border-radius: 9999px;
    color: var(--tda-primary-text-dark);
    background-color: var(--tda-panel-active);
    border: 2px solid var(--tda-panel-border);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .agentButtonContainer[hover],
  .agentButtonContainer:hover {
    background-color: var(--tda-panel-hover);
  }
  .productButtonContainer {
    font-size: 16px;
    border-radius: 4px;
    color: var(--tda-primary-text-light);
    background-color: var(--tda-light);
    border: 1px solid var(--tda-primary-text-light);
  }
  .productButtonContainer[hover],
  .productButtonContainer:hover {
    color: var(--tda-light);
    background-color:  var(--tda-primary-text-light);
  }
  .spinnerContainer{
    padding: 20%;
  }
  