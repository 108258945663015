.markdownContainer {
  max-width: 100%;
  overflow-wrap: break-word;
}

.markdownContainer a {
  overflow: hidden;
  word-break: break-all; 
  text-overflow: ellipsis;
}

.codeHeader {
  border-radius: 8px 8px 0px 0px;
  padding: 12px 16px;
}

.languageLabel {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
  letter-spacing: 0.15px;
  color: var(--tda-text);
}

.markdownContainer img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.markdownContainer table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.markdownContainer th, .markdownContainer td {
  border: 1px solid var(--tda-table-border);
  padding: 8px;
  text-align: left;
  color: var(--tda-dark);
}

.markdownContainer th {
  background-color: var(--tda-table-header-bg);
  color: var(--tda-dark);
  font-weight: bold;
}

.markdownContainer tr:nth-child(even) {
  background-color: var(--tda-table-row-bg);
  color: var(--tda-row-text);
}
