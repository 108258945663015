.headerText{
  color: var(--tda-primary-text);
  font-size: 20px;
}

.searchContainer{
  width: 400px;
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 16px 24px;
  padding: 20px;
}

.card {
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
  background: var(--tda-light);
  border: 1px solid var(--tda-tertiary-0);
}

.iconContainer {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-top: 2px;
}

.title {
  color: var(--tda-grey-10);
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.description {
  font-size: 12px;
  color: var(--tda-grey-10);
  text-align: left;
}

.selectedCard {
  background: var(--tda-primary-text);
}

.selectedIcon {
  color: var(--tda-light);
}

.selectedTitle {
  font-weight: 600;
  color: var(--tda-light);
}


.selectedDescription  {
  color: var(--tda-light);
}

.productSpinner{
  padding: 30%;
}

@media (width < 450px) {
  .searchContainer{
    padding: 0 20px;
    width: 100%;
  }
}
