.multiColumnDropdown {
    position: relative;
    height: auto;
    width: 100%;
}
.dropdownButton {
    width: 100%;
    cursor: pointer;
    background-color: var(--tda-button-bg); 
    border: 0;
    outline: none;
    color: var(--tda-button-txt);
    padding: 10px 0;
}
.dropdownContainer {
    display: flex; 
    background-color: var(--tda-dropdown-container); 
    flex-direction: row; 
    flex-wrap: wrap; 
    justify-content: center;
    padding-bottom: 10px;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
    transition: all 0.3s ease;
}
.grid {
    flex-basis: 95%;
}
.menuColumn {
    display: block;
}
.search {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}
.searchInput {
    width: 50%;
    background-color: var(--tda-dropdown-search-bg);
    color: var(--tda-input-text);
    padding: 7px 10px;
    border-radius: 5px;
    border: none;
    outline: 0;
}
.searchInput:focus {
    outline: none;
}
.menuColumn h3 {
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 0;
    font-size: 14px;
    width: 100%;
    color: var(--tda-text);
}
.menuColumn a {
    padding: 5px 20px; 
    text-decoration: none;
    text-decoration: none; 
    color: var(--tda-dropdown-link-text);
    background-color: var(--tda-dropdown-link-bg);
    margin-bottom: 2px;
    font-size: 12px;
    width: 100%;
    display: inline-block;
}
.menuColumn a:last-child {
    margin-bottom: 0;
}
.menuColumn a:hover {
    background-color: #0082d6;
    color: #fff
}
.menuColumn .selected {
    background-color: #0082d6;
    color: #fff
}


