.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconSmall {
    height: 24px;
    width: 24px;
}

.iconLarge {
    height: 32px;
    width: 32px;
}

.iconSmall svg {
    width: 24px;
    height: 16px;
}