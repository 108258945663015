.moreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  padding: 0px;
  cursor: pointer;
}

.unselectedItem {
  color: var(--tda-primary-contrast);
}

.selectedItem {
  color: var(--tda-dark);
}

.moreButton:focus,
.moreButton[focus] {
  box-shadow: none;
  color: inherit;
}

.moreButton[hover],
.moreButton:hover,
.moreButton-active {
  color: var(--tda-dark);
}

.moreButtonMenu {
  background-color: var(--tda-light);
  color: var(--tda-text);
  height: 32px;
  width: 64px;
  bottom: -28px;
  right: 0px;
  border-radius: 4px;
  position: absolute;
  overflow: hidden;
}
