.dateLabel {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px; /* 150% */
  letter-spacing: 0.05px;
  margin: 12px 0;
  color: var(--tda-label);
}

.promptParagraph {
  white-space: pre-wrap;
  overflow: auto;
}

.userImageSize {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.userAvatar {
  color: var(--tda-primary);
  filter: var(--tda-logo-filter);
}

@media screen and (max-width: 576px) {
  .userImageSize {
    height: 24px;
    width: 24px;
    margin-top: 4px;
  }
}
