/* DVH: https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units */
.historyContainer {
  height: var(--tda-main-container-height);
  width: 25%;
  /* background-color: var(--tda-sidebar); */
  color: var(--tda-text);
  flex-basis: 25%;
  flex-shrink: 0;
  z-index: 1;
  background-image: var(--tda-panel-background)
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.historyHeader {
  color: var(--tda-primary-contrast);
  font-size: 20px;
}

.historyListContainer {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.historyList {
  gap: 0.5rem;
  box-sizing: border-box;
}

.productButton {
  color: var(--tda-primary-contrast);
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 12px 8px;
  font-weight: 600;
}

/* Unfortunately not possible to use variables in container clause.*/
@media (width < 930px) {
  .historyContainer {
    position: absolute;
    z-index: 1;
    width: 35%;
  }
}
@media (width < 760px) {
  .historyContainer {
    position: absolute;
    z-index: 1;
    width: 50%;
  }
}
@media (width < 460px) {
  .historyContainer {
    position: absolute;
    z-index: 1;
    width: 80%;
  }
}
