.sidebarContainer {
  height: var(--tda-main-container-height);
  width: 280px;
  background-color: var(--tda-sidebar);
  color: var(--tda-text);
  flex-basis: 280px;
  flex-shrink: 0;
  top: 0;
  right: 0;
  padding: 10px;
}
.listContainer {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list {
  background-color: var(--tda-background);
  color: var(--tda-primary-text);
  width: 100%;
  padding: 8px 8px;
  cursor: pointer;
  font-weight: 700;
  display: block;
}
.list button {
  color: inherit;
  text-decoration: none;
  border: none;
  background-color: transparent;
  outline: none;
  font-weight: bold;
}
.list .list {
  background-color: transparent;
  padding: 4px 8px;
}
.list .list:hover {
  background-color:var(--tda-background-active);
  color: var(--tda-primary-text);
}
.list + .list {
  margin-top: 2px;
}
.list:hover,
.list[hover] {
  background-color: var(--tda-background-hover);
}

.list:active,
.list[active] {
  background-color: var(--tda-background-active);
}

.list:focus,
.list[focus] {
  box-shadow: none;
}
