.listContainer {
  list-style: none;
  padding: 0;
  margin: 0;
}
li > .listContainer {
  padding-left: 10px;
}
.list {
  color: var(--tda-text-light);
  width: 100%;
  padding: 4px 0;
  cursor: pointer;
  display: block;
}

.historyList {
  background-color: var(--tda-inner-panel-background);
  padding: 4px 0;
}

.headerBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem 0.25rem 0.5rem;
}
.list .list {
  background-color: transparent;
  padding: 4px 8px;
}
.list .list:hover {
  background-color: transparent;
  color: var(--tda-primary);
}
.list + .list {
  margin-top: 2px;
}

.list:focus,
.list[focus] {
  box-shadow: none;
}

.listHeader {
  color: var(--tda-primary-contrast);
  text-decoration: none;
  border: none;
  background-color: transparent;
  outline: none;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}

.newButton {
  background-color: var(--tda-sidebar);
  color: var(--tda-primary-text);
  border-radius: 4px;
  font-size: 12px;
}

.newButton:hover,
.newButton[hover] {
  color: var(--tda-button-bg);
}

.historyListContainer {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}
