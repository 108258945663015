.promptInput {
  resize: none;
  padding-right: 66px;
  height: auto;
  box-sizing: content-box;
  background-color: var(--tda-input-background);
  color: var(--tda-input-text);
}

.promptInput:focus {
  background-color: var(--tda-input-background);
  color: var(--tda-input-text);
}

.promptButton {
  position: absolute;
  bottom: 5px;
  right: 10px;
}
