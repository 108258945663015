:root {
  --tda-main-container-height: calc(100dvh);
}

.main_container {
  display: flex;
  flex-direction: row;
  height: 100dvh;
  background-color: var(--tda-background);
  color: var(--tda-text);
}

.conversation_container {
  padding-top: 64px;
  width: 680px;
  margin: 0 auto;
}

.prompt_container {
  width: 680px;
  padding-bottom: 64px;
  margin-right: 10px;
}

@media screen and (max-width: 992px) {
  .conversation_container {
    width: 80%;
  }
  .prompt_container {
    width: 80%;
  }
}

@media screen and (max-width: 576px) {
  .conversation_container {
    padding-top: 32px;
    width: 80%;
  }
  .prompt_container {
    width: 90%;
    padding-bottom: 32px;
    margin-right: 0;
  }
}
