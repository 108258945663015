.historyFooter {
  border-top: 1px solid var(--tda-tertiary);
  padding: 5px;
}
.menuList {
  color: var(--tda-primary);
  padding: 0;
  margin: 0;
  width:100%
}
.menuFooter {
  color: var(--tda-primary-contrast);
  justify-content: space-between;
  width: 100%;
  padding: 8px 8px;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 2px;
}

