.required {
  bottom: 0.0625rem;
  color: var(--modus-input-validation-error-color, #da212c);
  margin-left: 0.25rem;
  position: relative;
}

.textarea {
  min-height: 400px;
  background-color: var(--tda-input-background);
  color: var(--tda-input-text);
}

.textarea:focus {
  background-color: var(--tda-input-background);
  color: var(--tda-input-text);
}