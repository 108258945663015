@import '@trimble-oss/modus-icons/dist/modus-solid/fonts/modus-icons.css';
[tda-theme] {
  --tda-dark: var(--modus-dark, #252a2e);
  --tda-light: var(--modus-white, #fff);
  --tda-dark-gray: var(--modus-trimble-gray, #252a2e);
  --tda-light-gray: var(--modus-gray-light, #f1f1f6);
  --tda-gray-0: var(--modus-gray-0, #e0e1e9);
  --tda-gray-1: var(--modus-gray-1, #cbcdd6);
  --tda-gray-2: var(--modus-gray-2, #b7b9c3);
  --tda-gray-3: var(--modus-gray-3, #a3a6b1);
  --tda-gray-4: var(--modus-gray-4, #90939f);
  --tda-gray-5: var(--modus-gray-5, #7d808d);
  --tda-gray-6: var(--modus-gray-6, #6a6e79);
  --tda-gray-7: var(--modus-gray-7, #585c65);
  --tda-gray-8: var(--modus-gray-8, #464b52);
  --tda-gray-9: var(--modus-gray-9, #353a40);
  --tda-gray-10: var(--modus-gray-10, #171c1e);
  --tda-yellow-1: var(--modus-yellow-1, #ffbe00);
  --tda-yellow-2: var(--modus-yellow, #fbad26);
  --tda-yellow-3: var(--modus-yellow-pale, #fff5e4);
  --tda-primary: var(--modus-primary, #0063a3);
  --tda-primary-0: var(--modus-btn-primary-hover, #217cbb);
  --tda-primary-1: var(--modus-btn-primary-active, #004f83);
  --tda-primary-2: var(--modus-btn-primary-light, #009ad9);
  --tda-primary-dark: var(--modus-primary-dark, #0e416c);
  --tda-primary-contrast: var(--modus-white, #fff);
  --tda-secondary: var(--modus-secondary, #6a6e79);
  --tda-secondary-0: var(--modus-btn-secondary-hover, #7d808d);
  --tda-secondary-1: var(--modus-btn-secondary-active, #585c65);
  --tda-secondary-contrast: var(--modus-white, #fff);
  --tda-tertiary: var(--modus-tertiary, #cbcdd6);
  --tda-tertiary-0: var(--modus-btn-tertiary-hover, #e0e1e9);
  --tda-tertiary-1: var(--modus-btn-tertiary-active, #b7b9c3);
  --tda-tertiary-contrast: var(--modus-trimble-gray, #252a2e);
  --tda-warning: var(--modus-warning, #e49325);
  --tda-danger: var(--modus-danger, #da212c);
  --tda-success: var(--modus-success, #006638);
  --tda-modal-background: var(--modus-white, #fff);
  --tda-modal-text: var(--modus-trimble-gray, #252a2e);
  --tda-modal-borders: var(--modus-gray-0, #e0e1e9);
  --tda-disabled-input-background: var(--tda-gray-0);
  --tda-background: var(--tda-light);
  --tda-background-hover: var(--tda-gray-0);
  --tda-background-active: var(--tda-gray-1);
  --tda-text: var(--tda-dark-gray);
  --tda-text-hover: var(--tda-dark-gray);
  --tda-text-active: var(--tda-dark-gray);
  --tda-label: var(--tda-gray-8);
  --tda-label-hover: var(--tda-gray-8);
  --tda-label-active: var(--tda-gray-8);
  --tda-sidebar: var(--tda-light-gray);
  --tda-sidebar-hover: var(--tda-gray-0);
  --tda-sidebar-active: var(--tda-gray-1);
  --tda-navbar-background: var(--tda-light);
  --tda-navbar-text: var(--tda-dark);
  --tda-primary-text:var(--tda-primary);
  --tda-primary-text-dark:var(--tda-primary-1);
  --tda-primary-text-light:var(--tda-primary-2);
  --tda-navbar-hover: var(--tda-gray-0);
  --tda-navbar-active: var(--tda-gray-1);
  --tda-primary-hover: var(--tda-primary-0);
  --tda-primary-active: var(--tda-primary-1);
  --tda-secondary-hover: var(--tda-secondary-0);
  --tda-secondary-active: var(--tda-secondary-1);
  --tda-tertiary-hover: var(--tda-tertiary-0);
  --tda-tertiary-active: var(--tda-tertiary-1);
  --tda-button-bg: var(--modus-primary, #0063a3);
  --tda-button-txt: var(--tda-light);
  --tda-dropdown-container: var(--tda-light-gray);
  --tda-dropdown-search-bg: var(--modus-white);
  --tda-dropdown-link-bg: var(--tda-gray-0);
  --tda-dropdown-link-text: var(--modus-dark);
  --tda-panel-active:var(--tda-yellow-1);
  --tda-panel-border:var(--tda-yellow-2);
  --tda-panel-hover:var(--tda-yellow-3);
  --tda-inner-panel-background:var(--tda-primary-dark);
  --tda-panel-background:linear-gradient(180deg, #236297 0%, #0063a3 9%, #0e416c 78%, #0e416c 100%);
  --tda-table-border: var(--modus-gray-light, #ddd);
  --tda-table-header-bg: var(--modus-gray-light, #f2f2f2);
  --tda-table-row-bg: var(--modus-gray-light-alt, #f9f9f9);
}

[tda-theme='dark'] {
  --tda-button-bg: var(--modus-gray-10, #171c1e);
  --tda-button-txt: var(--tda-text);
  --tda-dropdown-search-bg: var(--modus-gray-10, #171c1e);
  --tda-dropdown-link-bg: var(--modus-gray-7, #585c65);
  --tda-dropdown-link-text: var(--modus-white);
  --tda-dark: var(--modus-white, #fff);
  --tda-light: var(--modus-trimble-gray, #252a2e);
  --tda-dark-gray: var(--modus-gray-light, #f1f1f6);
  --tda-light-gray: var(--modus-gray-9, #353a40);
  --tda-gray-0: var(--modus-gray-8, #464b52);
  --tda-gray-1: var(--modus-gray-7, #585c65);
  --tda-gray-2: var(--modus-gray-6, #6a6e79);
  --tda-gray-3: var(--modus-gray-5, #7d808d);
  --tda-gray-4: var(--modus-gray-4, #90939f);
  --tda-gray-5: var(--modus-gray-3, #a3a6b1);
  --tda-gray-6: var(--modus-gray-2, #b7b9c3);
  --tda-gray-7: var(--modus-gray-1, #cbcdd6);
  --tda-gray-8: var(--modus-gray-0, #e0e1e9);
  --tda-gray-9: var(--modus-gray-light, #f1f1f6);
  --tda-gray-10: var(--modus-white, #fff);
  --tda-modal-background: var(--modus-gray-9, #353a40);
  --tda-modal-text: var(--modus-gray-light, #f1f1f6);
  --tda-primary-text:var(--modus-white, #fff);
  --tda-primary-text-dark:var(--modus-white, #fff);
  --tda-primary-text-light:var(--modus-gray-0, #e0e1e9);
  --tda-modal-borders: var(--modus-gray-6, #6a6e79);
  --tda-input-background: var(--modus-gray-10, #171c1e);
  --tda-input-text: var(--tda-gray-7, #cbcdd6);
  --tda-disabled-input-background: var(--tda-gray-2);
  --tda-panel-active: var(--tda-gray-0);
  --tda-panel-border:var(--modus-trimble-gray, #252a2e);
  --tda-panel-hover: var(--modus-gray-9, #353a40);
  --tda-inner-panel-background: var(--modus-gray-10, #171c1e);
  --tda-panel-background: linear-gradient(180deg, #353a40 0%, #353a40 9%, #353a40 78%, #353a40 100%);
  --tda-table-border: var(--modus-gray-dark, #4a4a4a); 
  --tda-table-header-bg: var(--modus-gray-7, #585c65); 
  --tda-table-header-text: var(--modus-dark, #252a2e); 
  --tda-table-row-bg: var(--modus-gray-10, #171c1e); 
  --tda-table-row-text: var(--modus-white, #fff);
}

[tda-theme='custom'] {
  --tda-dark: var(--tda-custom-dark, #252a2e);
  --tda-light: var(--tda-custom-light, #fff);
  --tda-dark-gray: var(--tda-custom-dark-gray, #252a2e);
  --tda-light-gray: var(--tda-custom-light-gray, #f1f1f6);
  --tda-gray-0: var(--tda-custom-gray-0, #e0e1e9);
  --tda-gray-1: var(--tda-custom-gray-1, #cbcdd6);
  --tda-gray-2: var(--tda-custom-gray-2, #b7b9c3);
  --tda-gray-3: var(--tda-custom-gray-3, #a3a6b1);
  --tda-gray-4: var(--tda-custom-gray-4, #90939f);
  --tda-gray-5: var(--tda-custom-gray-5, #7d808d);
  --tda-gray-6: var(--tda-custom-gray-6, #6a6e79);
  --tda-gray-7: var(--tda-custom-gray-7, #585c65);
  --tda-gray-8: var(--tda-custom-gray-8, #464b52);
  --tda-gray-9: var(--tda-custom-gray-9, #353a40);
  --tda-gray-10: var(--tda-custom-gray-10, #171c1e);
  --tda-primary: var(--tda-custom-primary, #0063a3);
  --tda-primary-0: var(--tda-custom-primary-0, #217cbb);
  --tda-primary-1: var(--tda-custom-primary-1, #004f83);
  --tda-primary-contrast: var(--tda-custom-primary-contrast, #fff);
  --tda-secondary: var(--tda-custom-secondary, #6a6e79);
  --tda-secondary-0: var(--tda-custom-secondary-0, #7d808d);
  --tda-secondary-1: var(--tda-custom-secondary-1, #585c65);
  --tda-secondary-contrast: var(--tda-custom-secondary-contrast, #fff);
  --tda-tertiary: var(--tda-custom-tertiary, #cbcdd6);
  --tda-tertiary-0: var(--tda-custom-tertiary-0, #e0e1e9);
  --tda-tertiary-1: var(--tda-custom-tertiary-1, #b7b9c3);
  --tda-tertiary-contrast: var(--tda-custom-tertiary-contrast, #252a2e);
  --tda-warning: var(--tda-custom-warning, #e49325);
  --tda-danger: var(--tda-custom-danger, #da212c);
  --tda-success: var(--tda-custom-success, #006638);
  --tda-modal-background: var(--tda-custom-modal-background, #fff);
  --tda-modal-text: var(--tda-custom-modal-text, #252a2e);
  --tda-modal-borders: var(--tda-custom-modal-borders, #e0e1e9);
}