.glassContainer {
  position: absolute;
  background-color: rgba(37, 42, 46, 0.75);
  opacity: 1 !important;
}

.dialogTitle {
  color: var(--tda-modal-text, #252a2e) !important;
}

.dialogBorders {
  border-color: var(--tda-modal-borders, #e0e1e9);
}

.dialogContent {
  background-color: var(--tda-modal-background, #fff);
  color: var(--tda-modal-text, #252a2e);
}